import React, { useState } from 'react';
import { FaGooglePay } from 'react-icons/fa';
import { SiPhonepe, SiPaytm, SiUpwork } from 'react-icons/si';
import './Deposit.css';

const PaymentScreen = () => {
    const [amount, setAmount] = useState('');

  // Function to handle the amount input change
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };
  return (
    <div className="payment-container">
      <h2 className="payment-title">E - PAY</h2>
      <input 
        type="number" 
        className="payment-amount-input"
        value={amount}
        onChange={handleAmountChange}
        placeholder="Enter Amount"
      />

      <div className="payment-details">
        <p><strong>Remark: </strong>A41536328740882</p>
        <p><strong>UPI ID: </strong><span className="upi-id">7402913618@KBL</span></p>
      </div>

      <div className="qr-code-container">
        <img 
          src="https://media.istockphoto.com/id/828088276/vector/qr-code-illustration.jpg?s=1024x1024&w=is&k=20&c=J3NoWzg4Y5x8x8tPjB-oscJ48ITem2axmKcX5lMusSU=" 
          alt="QR Code" 
          className="qr-code" 
        />
        <button className="download-btn">Download</button>
      </div>

      <p className="success-message">Have you paid successfully?</p>
      
      <div className="payment-options">
        <div className="payment-option">
          <SiPaytm className="payment-icon paytm-icon" /> Paytm
        </div>
        <div className="payment-option">
          <SiPhonepe className="payment-icon phonepe-icon" /> PhonePe
        </div>
        <div className="payment-option">
          <FaGooglePay className="payment-icon gpay-icon" /> GooglePay
        </div>
        <div className="payment-option">
          <SiUpwork className="payment-icon other-icon" /> Other Bank
        </div>
      </div>

      <div className="utr-section">
  <h2 className="utr-label">UTR</h2>
  <input type="text" className="utr-input" placeholder="Input 12-digit here" />
  <button className="submit-btn-small">Submit</button>
</div>

    </div>
  );
};

export default PaymentScreen;
